<template>
    <DatatablePageLayout>
        <template v-slot:actionButtons>
            <button class="sk-btn sk-btn-primary rounded-lg" @click="handleCreate">
                {{ $t('apps.campaigns.form.createButton') }}
            </button>
        </template>
        <template v-slot:datatable>
            <DataTable
                :config="datatableConfig"
                :elements="users"
                :stripe="true"
                :draggable="false"
                :sst="true"
                :pagination="pagination"
                @filter="handleFilter"
                @action="handleAction"
            />
        </template>
    </DatatablePageLayout>
</template>

<script>

import DataTable from '@/modules/Shared/Components/table/SKDataTable.vue'
import loader from "@/modules/Shared/Mixins/loader";
import PaginationService from "@/modules/Shared/Services/PaginationService";
import UsersService from "@/modules/Admin/Services/UsersService";
import DatatablePageLayout from "@/modules/Shared/Pages/DatatablePageLayout.vue";

export default {
  name: "UsersPage",
  components: {DatatablePageLayout, DataTable},
  mixins: [loader],
  data() {
    return {
      users: [],
      typeOptions: [
          { value: 'editor', label: 'Editor' },
          { value: 'admin', label: 'Admin' }
      ],
      datatableConfig: [
          { 'field' : 'username', head : this.$t('shops.name'), 'searchable' : true, 'sortable' : false},
          { 'field' : 'email', head : this.$t('apps.registered-users.email'), 'searchable' : true, 'sortable' : false},
          { 'field' : 'type',
              head : this.$t('apps.bannerspaces.form.typeinput'),
              'searchable' : true,
              searchableoptions: [
                  { value: 'editor', label: 'Editor' },
                  { value: 'admin', label: 'Admin' }
              ],
              options: [
                  { value: 'editor', label: 'Editor' },
                  { value: 'admin', label: 'Admin' }
              ],
              'sortable' : false
          },
          { 'field' : 'actions', 'type': 'actions', head : this.$t("geofences.geofences-messages.actions"), 'searchable' : false, 'sortable' : false }
      ],
      pagination: {},
    }
  },
  async created() {
      this.tabIndex = this.$route.params.scopeTypeId
          ? this.$route.params.scopeTypeId - 1
          : 0
      await this.getUsers({
          filters: [{
              field: 'type',
              value: 'editor;admin'
          }],
          page: 1
      })
  },
  methods: {
      async getUsers(params) {
          await this.load(async () => {
              const users = await UsersService.getUsers(PaginationService.parseParameters(params))
              this.users =  this.parseUsers(users.data)
              this.pagination = {
                  per_page: users.per_page,
                  total: users.total
              }
          })
      },
      async handleFilter(params) {
          if (!params.filters.find(filter => filter.field === 'type')) {
              params.filters.push({ 'field': 'type', 'value': 'editor;admin' })
          }

          await this.getUsers(
              {
                  filters: params.filters,
                  page: params.page
              }
          )
      },
      handleCreate() {
          this.$router.push({
              name: 'admin-user-create',
              params: {
                  scopeType: this.scopeType
              }
          });
      },
      async handleAction(type, uuid){
          switch(type) {
              case 'edit':
                  await this.$router.push({
                      name: 'admin-user-edit',
                      params: {
                          'uuid': uuid,
                      }
                  });
                  break;
          }
      },
      parseUsers(users) {
          return users.map(user => ({
              ...user,
              actions: [ 'edit' ]
          }))
      }
  }
};
</script>
